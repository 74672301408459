import React, { Component } from 'react';
import httpService from '../services/httpService';
import swal from 'sweetalert';
import axios from 'axios';


class Whatsapp extends Component {
    state = {
        classes: {maternelle: [], primaire: [], secondaire: []},
        tab : true,
        niveau: 'maternelle',
        charge: true
    }

    componentDidMount() {
        this.getClasse('csa');
    }

    handleClick = school => {
        
        this.getClasse(school);
        if(school == "csaa") {
            this.setState({tab: false});
        } else {
            this.setState({tab: true});
        }
        
    }

    handleNiveau = section => {
        this.setState({niveau: section});
    }

    getClasse = (domaine) => {
        this.setState({classes: {
            maternelle: [],
            primaire: [],
            secondaire: []
        }});
        axios.get(
            "https://napoche-apis.eteyelo.com/KlasroomGateWay/get-classes?domaine="+domaine,
            { headers: {
                'Content-Type': 'application/json',
                'responseType': 'text'
            }}
        ).then (
           res => {
                if(res.data.status == 'SUCCESS') {
                    const classes = {
                        maternelle: [],
                        primaire: [],
                        secondaire: []
                    }
                    const donee = res.data.data.sort(this.filtrage)
                    donee.map(item => {
                        //console.log(res.data)
                        if(item.intitule.includes("PRIMAIRE")) {
                            classes.primaire.push(item);
                        } else if (item.intitule.includes("Humanit") || item.intitule.includes("base")) {
                            classes.secondaire.push(item);
                        } else {
                            classes.maternelle.push(item);
                        }
                    })
                    
                    this.setState({classes: classes});
                    
                } else {
                    swal("Info", "Aucune classe disponible !", "info");
                }
           } 
        )
        
    }

    handleScroll = () => {
        var elmntToView = document.getElementById("kls");
        elmntToView.scrollIntoView(); 
    }

    filtrage = (a, b) => {
        if (a.intitule > b.intitule) {
            return 1;
        }
        if (a.intitule < b.intitule) {
            return -1;
        }
        return 0;
    }

    render() {
        const {tab, niveau, classes, charge} = this.state;
        return(
            <>
                <section id="hero">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 d-flex align-items-center">
                                <div data-aos="zoom-out">
                                    <h2 style={{color: 'white'}}>Complexe Scolaire l'Age d'Or : <span style={{color: '#eba313'}}>BACK TO SCHOOL</span></h2>
                                    <p style={{color: 'white'}}>Programme de la rentrée scolaire du CS l'Age d'Or 2022-2023</p>
                                    <div className="text-center text-lg-left">
                                        <a onClick={this.handleScroll} type="button" className="btn-get-started scrollto m-1">Programme</a>
                                        <a onClick={this.handleScroll} type="button" className="btn-get-started scrollto m-1">WhatsappKlas</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <svg className="hero-waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 120 28 " preserveAspectRatio="none" >
                    <defs>
                        <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g className="wave1">
                        <use xlinkHref="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
                    </g>
                    <g className="wave2">
                        <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
                    </g>
                    <g className="wave3">
                        <use xlinkHref="#wave-path" x="50" y="9" fill="#fff" />
                    </g>
                    </svg>
                </section>
                <section id="contact" className="contact">
                    
                    <div className="container">
                        <div className="col-lg-12 m-1 p-1">
                                <img src="assets/img/age.png" className="img-fluid animated" width={'100px'} alt="" />
                        </div>
                        <div className="section-title" data-aos="fade-up">
                            <h2 id='kls'>WhatsappKlas</h2>
                        </div>

                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item m-1" role="presentation">
                                <button onClick={(e) => { e.preventDefault();this.handleClick ("csa")}} style={{border: 'none'}} className={'nav-link '+  (tab? 'active':"")} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">L'Age d'Or 1</button>
                            </li>
                            <li className="nav-item m-1" role="presentation">
                                <button onClick={(e) => { e.preventDefault();this.handleClick ("csaa")}} style={{border: 'none'}} className={"nav-link "+ (tab? "": 'active')} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">L'Age d'Or 2</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className={"tab-pane fade show "+ (tab? 'active':"")} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <button onClick={(e) => { e.preventDefault();this.handleNiveau("maternelle")}} className={"nav-link " + (niveau == 'maternelle'? "active": "")} id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Maternelle</button>
                                        <button onClick={(e) => {e.preventDefault();this.handleNiveau("primaire")}} className={"nav-link " + (niveau == 'primaire'? "active": "")} id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Primaire</button>
                                        <button onClick={(e) => {e.preventDefault();this.handleNiveau("secondaire")}} className={"nav-link " + (niveau == 'secondaire'? "active": "")} id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Secondaire</button>
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className={"tab-pane fade show " + (niveau == 'maternelle'? "active": "")} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                        
                                            {classes.maternelle.map( classe => 
                                                <div className="row m-1 p-2" key={classe.code}>
                                                    <div className="col-md-1">
                                                        <a href={classe.whatsappgroup == null || ""?"":classe.whatsappgroup} target="_blank">
                                                            <i className="icofont-brand-whatsapp" style={{fontSize: '28px', color: 'green'}}></i>
                                                        </a>
                                                    </div>
                                                    <div className="col-md-11">
                                                    <a href={classe.whatsappgroup == null || ""?"":classe.whatsappgroup} target="_blank">
                                                        <p style={{color: 'black'}}>{classe.intitule}</p>
                                                    </a>
                                                    </div>
                                                </div>
                                            
                                            )}
                                        
                                    </div>
                                    <div className={"tab-pane fade show " + (niveau == 'primaire'? "active": "")} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                        {classes.primaire.map( classe => 
                                                <div className="row m-1 p-2" key={classe.code}>
                                                    <div className="col-md-1">
                                                        <a href={classe.whatsappgroup == null || ""?"":classe.whatsappgroup} target="_blank">
                                                            <i className="icofont-brand-whatsapp" style={{fontSize: '28px', color: 'green'}}></i>
                                                        </a>
                                                    </div>
                                                    <div className="col-md-11">
                                                    <a href={classe.whatsappgroup == null || ""?"":classe.whatsappgroup} target="_blank">
                                                        <p style={{color: 'black'}}>{classe.intitule}</p>
                                                    </a>
                                                    </div>
                                                </div>
                                            
                                            )}
                                    </div>
                                    <div className={"tab-pane fade show " + (niveau == 'secondaire'? "active": "")} id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                        {classes.secondaire.map( classe => 
                                                <div className="row m-1 p-2" key={classe.code}>
                                                    <div className="col-md-1">
                                                        <a href={classe.whatsappgroup == null || ""?"":classe.whatsappgroup} target="_blank">
                                                            <i className="icofont-brand-whatsapp" style={{fontSize: '28px', color: 'green'}}></i>
                                                        </a>
                                                    </div>
                                                    <div className="col-md-11">
                                                    <a href={classe.whatsappgroup == null || ""?"":classe.whatsappgroup} target="_blank">
                                                        <p style={{color: 'black'}}>{classe.intitule}</p>
                                                    </a>
                                                    </div>
                                                </div>
                                            
                                            )}
                                    </div>
                                </div>
                            </div>
                            <div className={"tab-pane fade show "+ (tab? "": 'active')} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab1" role="tablist">
                                        <button onClick={(e) => {e.preventDefault();this.handleNiveau("maternelle")}} className={"nav-link " + (niveau == 'maternelle'? "active": "")} id="nav-home-tab1" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Maternelle</button>
                                        <button onClick={(e) => {e.preventDefault();this.handleNiveau("primaire")}} className={"nav-link " + (niveau == 'primaire'? "active": "")} id="nav-profile-tab1" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Primaire</button>
                                        <button onClick={(e) => {e.preventDefault();this.handleNiveau("secondaire")}} className={"nav-link " + (niveau == 'secondaire'? "active": "")} id="nav-contact-tab1" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Secondaire</button>
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent1">
                                    <div className={"tab-pane fade show " + (niveau == 'maternelle'? "active": "")} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab1">
                                        {classes.maternelle.map( classe => 
                                                <div className="row m-1 p-2" key={classe.code}>
                                                    <div className="col-md-1">
                                                        <a href={classe.whatsappgroup == null || ""?"":classe.whatsappgroup} target="_blank">
                                                            <i className="icofont-brand-whatsapp" style={{fontSize: '28px', color: 'green'}}></i>
                                                        </a>
                                                    </div>
                                                    <div className="col-md-11">
                                                        <a href={classe.whatsappgroup == null || ""?"":classe.whatsappgroup} target="_blank">
                                                        <p style={{color: 'black'}}>{classe.intitule}</p>
                                                        </a>
                                                    </div>
                                                </div>
                                            
                                            )}
                                    </div>
                                    <div className={"tab-pane fade show " + (niveau == 'primaire'? "active": "")} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab1">
                                        {classes.primaire.map( classe => 
                                                <div className="row m-1 p-2" key={classe.code}>
                                                    <div className="col-md-1">
                                                        <a href={classe.whatsappgroup == null || ""?"":classe.whatsappgroup} target="_blank">
                                                            <i className="icofont-brand-whatsapp" style={{fontSize: '28px', color: 'green'}}></i>
                                                        </a>
                                                    </div>
                                                    <div className="col-md-11">
                                                    <a href={classe.whatsappgroup == null || ""?"":classe.whatsappgroup} target="_blank">
                                                        <p style={{color: 'black'}}>{classe.intitule}</p>
                                                        </a>
                                                    </div>
                                                </div>
                                            
                                            )}
                                    </div>
                                    <div className={"tab-pane fade show " + (niveau == 'secondaire'? "active": "")} id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab1">
                                        {classes.secondaire.map( classe => 
                                                <div className="row m-1 p-2" key={classe.code}>
                                                    <div className="col-md-1">
                                                        <a href={classe.whatsappgroup == null || ""?"":classe.whatsappgroup} target="_blank">
                                                            <i className="icofont-brand-whatsapp" style={{fontSize: '28px', color: 'green'}}></i>
                                                        </a>
                                                    </div>
                                                    <div className="col-md-11">
                                                    <a href={classe.whatsappgroup == null || ""?"":classe.whatsappgroup} target="_blank">
                                                        <p style={{color: 'black'}}>{classe.intitule}</p>
                                                        </a>
                                                    </div>
                                                </div>
                                            
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </section>
            </>
        )
    }

}

export default Whatsapp;