import './App.css';
import React from 'react';
import Footer from './components/footer';
import Navigation from './components/navigation';
import Inscription from './components/inscription';
import { Redirect, Route, Switch } from "react-router-dom";
import Whatsapp from './components/whatsapp';

function App() {
  const myRef = React.createRef();
  const myRef1 = React.createRef();
  const myRef2 = React.createRef();
 
  return (
        <React.Fragment>
          <Switch>
            <Route path="/home" render={() => <Navigation myRef={myRef} myRef1={myRef1} myRef2={myRef2} /> }/>
            <Route path="/inscription" component={Inscription} />
            <Route exact path="/agedor" component={Whatsapp} />
            <Route path="/" render={props => <Navigation myRef={myRef} myRef1={myRef1} myRef2={myRef2}/> } />
          </Switch>
          <Footer />
        </React.Fragment>

  );
}

export default App;

