import React, { Component } from 'react';
class Fonctionnalite extends Component {
    render() { 
        return ( 
            <React.Fragment>
                <section id="about" className="about">
                    <div className="container">
                        <div className="section-title" data-aos="fade-up">
                            <h2></h2>
                            <p>Apprentissage centré sur l'élève</p>
                        </div>
                        <div className="container" data-aos="fade-left">
                            <div className="row">
                                <div className="col-xl-3 video-box d-flex justify-content-center align-items-stretch" data-aos="fade-right" data-aos="fade-right">
                                    <video width="300" controls className="vid">
                                        <source src="assets/img/v3_Trim.mp4" type="video/mp4" alt="" className="img-fluid" />
                                    </video>
                                </div>
                                <div className="col-xl-9 col-lg-9 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-1 px-lg-1" data-aos="fade-left">
                                    <div className="icon-box" data-aos="zoom-in" data-aos-delay="100">
                                       <h4 className="title">Communication Elèves-Enseignants en toute simplicité.</h4>
                                        <p className="description">La communication entre les enseignants et les élèves est l'un des principaux points sur lesquels notre école se concentre ; Pour la rendre efficace, KlasRoom facilite enseignants et apprenants à communiquer où qu'ils soient et peu importe les limites logistiques. 

                                        <br/>KlasRoom accompagne les enseignants à prouver leur grande compétence en tant qu'enseignant et garantit la réussite scolaire des élèves !</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-3 col-lg-3 video-box d-flex justify-content-center align-items-stretch" data-aos="fade-right">
                                </div>
                                <div className="col-xl-9 col-lg-9 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-1 px-lg-1" data-aos="fade-left">
                                
                                <div className="icon-box" data-aos="zoom-in" data-aos-delay="300">
                                    <h4 className="title">Apprendre à tous les niveaux.</h4>
                                    <p className="description">KlasRoom fournit un apprentissage précieux aux élèves tout au long de leur parcours scolaire. Le programme de cours s’adapte à la progression des élèves, en proposant les activités qui continuent à les interpeller et à les captiver.</p>
                                </div>

                            </div>
                            </div>
                        </div>

                    </div>
                </section>
            </React.Fragment>
         );
    }
}
 
export default Fonctionnalite;