import React, { Component } from 'react';

class Temoignage extends Component {
    render() { 
        const {myRef} = this.props;
        return ( 
            <React.Fragment>
                <section ref={myRef} id="gallery" className="gallery">
                    <div classNameName="container">
                        <div classNameName="section-title" data-aos="fade-up">
                            <h2>Gallerie</h2>
                        </div>
                        <div className="row no-gutters" data-aos="fade-left">
                            <div className="col-lg-3 col-md-4">
                                <div className="gallery-item" data-aos="zoom-in" data-aos-delay="450">
                                    <a href="assets/img/gallery/v2.mp4" className="venobox" data-gall="gallery-item">
                                        <video width="282" controls>
                                            <source src="assets/img/gallery/v2.mp4" type="video/mp4" alt="" className="img-fluid" />
                                        </video>
                                    </a>
                                    
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4">
                                <div className="gallery-item" data-aos="zoom-in" data-aos-delay="450">
                                    <a href="assets/img/gallery/1.jpg" className="venobox" data-gall="gallery-item">
                                        <img src="assets/img/gallery/1.jpg" alt="" className="img-fluid" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4">
                                <div className="gallery-item" data-aos="zoom-in" data-aos-delay="450">
                                    <a href="assets/img/gallery/2.jpg" className="venobox" data-gall="gallery-item">
                                        <img src="assets/img/gallery/2.jpg" alt="" className="img-fluid" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4">
                                <div className="gallery-item" data-aos="zoom-in" data-aos-delay="450">
                                    <a href="assets/img/gallery/3.jpg" className="venobox" data-gall="gallery-item">
                                        <img src="assets/img/gallery/3.jpg" alt="" className="img-fluid" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4">
                                <div className="gallery-item" data-aos="zoom-in" data-aos-delay="450">
                                    <a href="assets/img/gallery/4.jpg" className="venobox" data-gall="gallery-item">
                                        <img src="assets/img/gallery/4.jpg" alt="" className="img-fluid" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4">
                                <div className="gallery-item" data-aos="zoom-in" data-aos-delay="450">
                                    <a href="assets/img/gallery/5.jpeg" className="venobox" data-gall="gallery-item">
                                        <img src="assets/img/gallery/5.jpeg" alt="" className="img-fluid" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4">
                                <div className="gallery-item" data-aos="zoom-in" data-aos-delay="450">
                                    <a href="assets/img/gallery/6.jpg" className="venobox" data-gall="gallery-item">
                                        <img src="assets/img/gallery/6.jpg" alt="" className="img-fluid" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4">
                                <div className="gallery-item" data-aos="zoom-in" data-aos-delay="450">
                                    <a href="assets/img/gallery/v1.mp4" className="venobox" data-gall="gallery-item">
                                        <video width="362" controls>
                                            <source src="assets/img/gallery/v1.mp4" type="video/mp4" alt="" className="img-fluid" />
                                        </video>
                                    </a>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
         );
    }
}
 
export default Temoignage;