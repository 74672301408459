import React, { Component } from 'react';

class Home extends Component {
    voir = () => {
        return 0;
    }
    render() { 
        return ( 
            <React.Fragment>
                <section id="features" className="features">
                    <div className="container">
                        <div className="section-title" data-aos="fade-up">
                            <h2></h2>
                            <p>Nos sponsors</p>
                        </div>
                        <div classNameName="row image">
                            <img src="assets/img/sponsors-logos.png"/>
                        </div>

                    </div>
                </section>
            </React.Fragment>
         );
    }
}

export function voir () {
    return 0;
}
 
export default Home;