import React, { Component } from 'react';
class About extends Component {
    render() { 
        return ( 
            <React.Fragment>
                <section id="details" className="details">
                    <div className="container">
                        <div className="section-title" data-aos="fade-up">
                            <h2></h2>
                        </div>
                        <div className="row content">
                            <div className="col-md-4" data-aos="fade-right">
                                <img src="assets/img/enfant.jpg" className="img-fluid i" alt="" />
                            </div>
                            <div className="col-md-8 pt-4" data-aos="fade-up">
                                <h3>Plus de ressources pour les élèves</h3>
                                <ul>
                                    <li>
                                        <div className="row">
                                            <div className="col-md-1">
                                                <i className="icofont-check" ></i>
                                            </div>
                                            <div className="col-md-11">
                                                <p>Notre catalogue des leçons est disponible en ligne. Les élèves ont accès aux leçons les en ligne et peuvent passer des exercices et épreuves tout au long de leur parcours d'apprentissage.</p>
                                            </div>
                                        </div> 
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="row content">
                                <div className="col-md-4 order-1 order-md-2" data-aos="fade-left">
                                    <img src="assets/img/enseignant.jpeg" className="img-fluid i" alt=""/>
                                </div>
                                <div className="col-md-8 pt-5 order-2 order-md-1" data-aos="fade-up">
                                    <h3>Plus de potentiels pour les enseignants</h3>
                                    <ul>
                                    <li>
                                        <div className="row">
                                            <div className="col-md-1">
                                                <i className="icofont-check" ></i>
                                            </div>
                                            <div className="col-md-11">
                                                <p>Nos enseignants ont un accès approfondi à des information sur la progression des classNamees et des apprenants.
                                                </p>
                                            </div>
                                        </div> 
                                    </li>
                                    <li>
                                        <div className="row">
                                            <div className="col-md-1">
                                                <i className="icofont-check" ></i>
                                            </div>
                                            <div className="col-md-11">
                                                <p>Ces rapports sont utilisés pour déterminer les domaines dans lesquels les élèves excellent, les domaines dans lesquels ils sont mis au défi et la manière de concevoir les activités et les exercices futurs pour répondre à tous les besoins d'apprentissage. </p>
                                            </div>
                                        </div> 
                                    </li>
                                    <li>
                                        <div className="row">
                                            <div className="col-md-1">
                                                <i className="icofont-check" ></i>
                                            </div>
                                            <div className="col-md-11">
                                                <p>Le programme se développe au fur et à mesure que les élèves grandissent, en proposant des visuels actualisés, des défis et des activités qui continuent à les interpeller et à les captiver. </p>
                                            </div>
                                        </div> 
                                    </li>
                                    </ul>
                            </div>
                        </div>

                        <div className="row content">
                        <div className="col-md-4" data-aos="fade-right">
                            <img src="assets/img/details-3.png" className="img-fluid" alt=""/>
                        </div>
                        <div className="col-md-8 pt-5 voir" data-aos="fade-up">
                            <h3>Plus d'autorité pour les parents</h3>
                            <ul>
                            <li>
                                <div className="row">
                                <div className="col-md-1">
                                    <i className="icofont-check" ></i>
                                </div>
                                <div className="col-md-11">
                                    <p className="title">Connectez-vous</p>
                                    Pensez à se connecter comme si vous alliez suivre l'évolution de vos enfants (élèves) à l'école, comme si vous participiez aux réunions des parents.
                                    En fait, c'est la même chose !</div>
                                </div> 
                            </li>
                            <li>
                                <div className="row">
                                <div className="col-md-1">
                                    <i className="icofont-check" ></i>
                                </div>
                                <div className="col-md-11">
                                    <p className="title">Visualisez tout</p>
                                    Nous reconnaissons votre autorité sur la scolarité de vos enfants. Vous le méritez. Suivez à chaque moment les évaluations que reçoivent vos enfants, les notes et commentaires des enseignants à propos de vos enfants. </div>
                                </div> 
                            </li>
                            </ul>
                        </div>
                        </div>

                    </div>
                </section>
            </React.Fragment>
        );
    }
}
 
export default About;