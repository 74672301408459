import React from 'react';
import { Link } from 'react-router-dom';

const Hero = ({myRef2}) => {
    return ( 
        <React.Fragment>
            <section id="hero" ref={myRef2}>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 pt-5 pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
                            <div data-aos="zoom-out">
                                <h1 style={{color: 'white'}}>Formation à portée de main</h1>
                                <p>Eteyelo KlasRoom est votre école en ligne, nos enseignants sont en interaction avec les apprenants tout au long de leurs cursus loin de barrière logistiques.</p>
                                <div className="text-center text-lg-left">
                                    <Link to="/inscription" className="btn-get-started scrollto">Inscription</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 order-1 order-lg-2 hero-img" data-aos="zoom-out" data-aos-delay="100">
                            <img src="assets/img/hero-img.png" className="img-fluid animated" alt="" />
                        </div>
                    </div>
                </div>

                <svg className="hero-waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28 " preserveAspectRatio="none" >
                    <defs>
                        <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g className="wave1">
                        <use xlinkHref="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
                    </g>
                    <g className="wave2">
                        <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
                    </g>
                    <g className="wave3">
                        <use xlinkHref="#wave-path" x="50" y="9" fill="#fff" />
                    </g>
                    </svg>

            </section>
        </React.Fragment>
     );
}
 
export default Hero;