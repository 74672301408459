import React, { Component } from 'react';
class Footer extends Component {
    state = {
        date: (new Date()).getFullYear()
    }
    render() { 
        return ( 
            <React.Fragment>
                <footer id="footer">
                    <div className="container">
                    <div className="copyright">
                        <span><strong>KlasRoom.Eteyelo </strong> &copy; {this.state.date}</span>
                    </div>
                    </div>
                </footer>
            </React.Fragment>
         );
    }
}
 
export default Footer;