import React, { Component } from 'react';
import Fonctionnalite from './fonctionnalite';
import About from './about';
import Temoignage from './temoignage';
import Contact from './contact';
import Hero from './hero';
import Home from './home';
import Navbar from './Navbar';


class Navigation extends Component {
    state = {  }
    render() { 
        const {myRef, myRef1, myRef2} = this.props;
        const executeScroll = () => {
            myRef.current.scrollIntoView();
          }
          const executeScroll1 = () => {
            myRef1.current.scrollIntoView()
          }
          const executeScroll2 = () => {
            myRef2.current.scrollIntoView()
          }
        return ( 
            <React.Fragment>
                <Navbar executeScroll={executeScroll} executeScroll1={executeScroll1} executeScroll2={executeScroll2}/>
                <Hero myRef2={myRef2}/>
                    <Fonctionnalite />
                    <About />
                    <Temoignage myRef={myRef}/>
                    <Home />
                    <Contact myRef1={myRef1}/> 
            </React.Fragment>
        );
    }
}
 
export default Navigation;