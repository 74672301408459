import React, {Component } from 'react';
import { Link } from 'react-router-dom';

class Navbar extends Component {
    state = {  }
    render() { 
        const { executeScroll, executeScroll1, executeScroll2 } = this.props;
        return ( 
            <React.Fragment>
                 <header id="header" className="fixed-top d-flex align-items-center header-transparent">
                    <div className="container d-flex align-items-center">
    
                        <div className="logo mr-auto">
                            <Link to="/home"><img src="assets/img/logoW.png" alt="KlasRoom" className="img-fluid" width="170" /></Link>
                        </div>
    
                        <nav className="nav-menu d-none d-lg-block">
                            <ul>
                                <li className="active"><Link onClick={executeScroll2}>Accueil</Link></li>
                                <li>
                                <b><Link to="/inscription">Inscription</Link></b>
                                </li>
                                <li><a href="https://klasroom-app.eteyelo.com" target="_blank">Résultat Scolaire</a></li>
                                <li><a href="https://klasroom-app.eteyelo.com" target="_blank">Horaire des cours</a></li>
                                <li><a href="https://klasroom-app.eteyelo.com" target="_blank">Communiqués</a></li>
                                <li><Link onClick={executeScroll}>Gallerie</Link></li>
                                <li><Link onClick={executeScroll1}>Contact</Link></li>
                                <li><a href="https://klasroom-app.eteyelo.com" target="_blank">Connexion</a></li>
                                
        
                            </ul>
                        </nav>
    
                    </div>
                    </header>
            </React.Fragment>
        );
    }
}
 
export default Navbar;