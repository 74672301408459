import React, { Component } from 'react';
import httpService from '../services/httpService';
import swal from 'sweetalert';
import Navbar from './Navbar';
import axios from 'axios';

class Inscription extends Component {
    state = { 
        classes : [],
        edisable: false,
        positionValide : [],
        displayEcole: 'none',
        displayTelephone: 'none',
        displayValide: 'none',
        ecole: "",
        message: "",
        phone: "",
        show: 'none',
        badge: null,
        tuteurEmail: "",
        logo: "",
        disable: true,
        checked: true,
        unchecked: false,
        enfants: [],
        subscription: 
            {
                nomEleve: null,
                postnomEleve: null,
                prenomEleve: null,
                dateNaissanceEleve: null,
                lieuNaissance: null,
                sexeEleve: "Homme",
                classe: null,
                nomTuteur: null,
                postnomTuteur: null,
                prenomTuteur: null,
                telephoneTuteur: null,
                emailTuteur: null,
                sexeTuteur: "Homme",
                adresse: null,
                codeEcole: null
        
        
            }
    }

    handelChange = (value) => {
        const subscription = this.state.subscription;
        if (value.target.name == "phone") {
            const phone = value.target.value;
            this.setState({phone});
        } else if ( value.target.name == "tuteurEmail"){
            this.setState({tuteurEmail: value.target.value});
        } else {
            subscription[value.target.name] = value.target.value;
            this.setState({subscription});
        }
        
    }

    handelInscript = (value) => {
        if (value.target.value == 1) {
            document.getElementById("reinscription").style.display = "none";
            document.getElementById("inscription").style.display = "block";
            this.setState({checked: true});
            this.setState({unchecked: false});
        }
        if (value.target.value == 2) {
            document.getElementById("reinscription").style.display = "block";
            document.getElementById("inscription").style.display = "none";
            this.setState({checked: false});
            this.setState({unchecked: true});
        }
    }

    handelEcole = () => {
        this.setState({displayEcole: 'inline-block'});
        const {subscription} = this.state;         
        
        axios.get(
            "https://registration-api.eteyelo.com/registration/load-school/"+subscription["codeEcole"]+"?api-version=1.0",
            { headers: {
                'Content-Type': 'application/json',
                'responseType': 'text'
            }}
        ).then(
            res => {
                const subscription = this.state.subscription;
                const ecole = res.data.data.ecole.intitule;
                const logo = res.data.data.ecole.logo;
                const disable = false;
                this.setState({ecole});
                this.setState({logo});
                this.setState({displayEcole: 'none'});
                if (res.data.data.classes.length > 0) {
                    this.setState({edisable: true});
                    this.setState({disable});
                    const classes = res.data.data.classes;
                    subscription.classe = classes[0].codeClasse;
                    this.setState({classes});
                    this.setState({subscription});
                } else {
                    this.setState({message: ""});
                    swal("Info", "cette école n'a aucune place de libre !", "info");
                }
                
                
            }
        ).catch(
            err => {
                this.setState({ecole: ""});
                this.setState({logo: ""});
                this.setState({classes: []});
                swal("Erreur", "cette école n'existe pas !", "error");
            }
        )
    }

    handelPhone = () => {
        this.setState({displayTelephone: 'inline-block'});
        const {subscription, phone} = this.state;  
        const obj = {
            codeEcole: subscription["codeEcole"],
            phone: phone
        }
        axios.post(
            "https://registration-api.eteyelo.com/registration/load-children?api-version=1.0",
            obj,
            { headers: {
                'Content-Type': 'application/json'
            },
            responseType: 'json'
        }
        ).then(
            res => {
                const enfants = res.data.data;
                this.setState({enfants})
                this.setState({displayTelephone: 'none'});
            }
        ).catch(
            err => {
                swal("Erreur", err.response.data.message, "error");
            }
        )
    }

    handelSelect = (value) => {
        
        const positionValide = this.state.positionValide;
        if (positionValide.includes(value.target.value)) {
            positionValide.pop(value.target.value)
        } else {
            positionValide.push(value.target.value)
        }
        this.setState({positionValide});
        //console.log(positionValide)
    }

    handelSolicite = (value) => {
        const {enfants, subscription} = this.state;
        let tab = value.target.value;
        tab = tab.split('-');
        const codeClasseDestination = tab[1];
        const pos = parseInt(tab[0]);
        
        enfants[pos]['codeClasseDestination'] = codeClasseDestination;
        enfants[pos]['codeEcole'] = subscription["codeEcole"];
        this.setState({enfants});
    }

    handleSubmit = () => {
        this.setState({displayValide: 'inline-block'});
        const subscription = this.state.subscription;
        let valide = true;
        Object.keys(subscription).map(function(key) {
            if (subscription[key] == null) {
                document.getElementsByName(key)[0].style.border = "solid 1px red";
                valide = false;
            } else {
                document.getElementsByName(key)[0].style.border = "solid 1px #3f43fd";
            }
            if(key == "emailTuteur") {
                valide = true;
                document.getElementsByName('emailTuteur')[0].style.border = "solid 1px #3f43fd";
            }
            
        })
        if (subscription.telephoneTuteur != null) {
            if(subscription.telephoneTuteur.length > 9 || subscription.telephoneTuteur.length < 9) {
                valide = false;
            } else {
                subscription.telephoneTuteur = "243"+subscription.telephoneTuteur;
            }
        }
        if (valide) {
            const data = {
                email: subscription.mail,
                message:
                "<!doctype html>"+
                "<html>"+
                    "<head>"+
                        "<meta charset='utf-8'>"+
                        "<meta name='viewport' content='width=device-width, initial-scale=1'>"+
                        "<title>+KlasRoom</title>"+
                        "<link href='https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css' rel='stylesheet'>"+
                        "<link href='' rel='stylesheet'>"+
                        "<script type='text/javascript' src='https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js'>"+"</script>"+
                        "<script type='text/javascript' src='https://stackpath.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.bundle.min.js'>"+"</script>"+
                        "<script type='text/javascript'>"+"</script>"+
                    "</head>"+
                    "<body oncontextmenu='return false' class='snippet-body'>"+
                        "<!DOCTYPE html>"+
                        "<html>"+
                            "<head>"+
                                "<meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\" />"+
                                "<meta name=\"viewport\" content=\"width=device-width, initial-scale=1\">"+
                                "<meta http-equiv=\"X-UA-Compatible\" content=\"IE=edge\" />"+
                                "<style type=\"text/css\">"+
                                    "@media screen {"+
                                        "@font-face {font-family: 'Lato';font-style: normal;font-weight: 400;"+
                                            "src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v11/qIIYRU-oROkIk8vfvxw6QvesZW2xOQ-xsNqO47m55DA.woff) format('woff');}"+
            
                                        "@font-face { font-family: 'Lato';font-style: normal;font-weight: 700;"+
                                            "src: local('Lato Bold'), local('Lato-Bold'), url(https://fonts.gstatic.com/s/lato/v11/qdgUG4U09HnJwhYI-uK18wLUuEpTyoUstqEm5AMlJo4.woff) format('woff');}"+
            
                                        "@font-face {font-family: 'Lato';font-style: italic;font-weight: 400;"+
                                            "src: local('Lato Italic'), local('Lato-Italic'), url(https://fonts.gstatic.com/s/lato/v11/RYyZNoeFgb0l7W3Vu1aSWOvvDin1pK8aKteLpeZ5c0A.woff) format('woff');}"+
            
                                        "@font-face {font-family: 'Lato';font-style: italic;font-weight: 700;"+
                                            "src: local('Lato Bold Italic'), local('Lato-BoldItalic'), url(https://fonts.gstatic.com/s/lato/v11/HkF_qI1x_noxlxhrhMQYELO3LdcAZYWl9Si6vvxL-qU.woff) format('woff');}"+
                                    "}body,table,td,a {-webkit-text-size-adjust: 100%;-ms-text-size-adjust: 100%;}"+
            
                                    "table,td {mso-table-lspace: 0pt;mso-table-rspace: 0pt;}"+
            
                                    "img {-ms-interpolation-mode: bicubic;}"+
            
                                    "img {border: 0;height: auto;line-height: 100%;outline: none;text-decoration: none;}"+
            
                                    "table {border-collapse: collapse !important;}"+
            
                                    "body { height: 100% !important;margin: 0 !important;padding: 0 !important;width: 100% !important;}"+
            
                                    "a[x-apple-data-detectors] {color: inherit !important;text-decoration: none !important;font-size: inherit !important;font-family: inherit !important;font-weight: inherit !important;line-height: inherit !important;}"+
            
                                    "@media screen and (max-width:600px) {h1 {font-size: 32px !important;line-height: 32px !important;}}"+
            
                                    "div[style*=\"margin: 16px 0;\"] {margin: 0 !important;}"+
                                "</style>"+
                            "</head>"+
            
                            "<body style=\"background-color: #f4f4f4; margin: 0 !important; padding: 0 !important;\">"+
                                "<div style=\"display: none; font-size: 1px; color: #fefefe; line-height: 1px; font-family: 'Lato', Helvetica, Arial, sans-serif; max-height: 0px; max-width: 0px; opacity: 0; overflow: hidden;\">"+"</div>"+
                                "<table border=\"0\" cellpadding=\"0\" cellspacing=\"0\" width=\"100%\">"+
                                    "<tr>"+
                                        "<td bgcolor=\"#007bff\" align=\"center\">"+
                                            "<table border=\"0\" cellpadding=\"0\" cellspacing=\"0\" width=\"100%\" style=\"max-width: 600px;\">"+
                                                "<tr>"+
                                                    "<td align=\"center\" valign=\"top\" style=\"padding: 40px 10px 40px 10px;\"></td>"+
                                                "</tr>"+
                                            "</table>"+
                                        "</td>"+
                                    "</tr>"+
                                    "<tr>"+
                                        "<td bgcolor=\"#007bff\" align=\"center\" style=\"padding: 0px 10px 0px 10px;\">"+
                                            "<table border=\"0\" cellpadding=\"0\" cellspacing=\"0\" width=\"100%\" style=\"max-width: 600px;\">"+
                                                "<tr>"+
                                                    "<td bgcolor=\"#ffffff\" align=\"center\" valign=\"top\" style=\"padding: 40px 20px 20px 20px; border-radius: 4px 4px 0px 0px; color: #111111; font-family: 'Lato', Helvetica, Arial, sans-serif; font-size: 48px; font-weight: 400; letter-spacing: 4px; line-height: 48px;\">"+
                                                        "<img src=\"https://firebasestorage.googleapis.com/v0/b/klasroom-ae6da.appspot.com/o/logok.png?alt=media&token=9e41439f-4214-4bfd-8e8d-975bef930e83\" width=\"150\" height=\"120\" style=\"display: block; border: 0px;\" />"+
                                                        "<h1 style=\"font-size: 32px; font-weight: 380; margin: 2;\">inscription</h1>"+
                                                    "</td>"+
                                                "</tr>"+
                                            "</table>"+
                                        "</td>"+
                                    "</tr>"+
                                    "<tr>"+
                                        "<td bgcolor=\"#f4f4f4\" align=\"center\" style=\"padding: 0px 10px 0px 10px;\">"+
                                            "<table border=\"0\" cellpadding=\"0\" cellspacing=\"0\" width=\"100%\" style=\"max-width: 600px;\">"+
                                                "<tr>"+
                                                    "<td bgcolor=\"#ffffff\" align=\"left\" style=\"padding: 20px 30px 40px 30px; color: #666666; font-family: 'Lato', Helvetica, Arial, sans-serif; font-size: 18px; font-weight: 400; line-height: 25px;\">"+
                                                        "<p style=\"margin: 0; text-align: center;\">Nous avons bien reçu votre demande d’inscription, nous vous revenons dans 72 heure après le traitement du dossier.<br>Merci vérifier votre messagerie.</p>"+
                                                    "</td>"+
                                                "</tr>"+
                                            "</table>"+
                                        "</td>"+
                                    "</tr>"+
                                    
                                "</table>"+
                            "</body>"+
            
                        "</html>"+
                    "</body>"+
            "</html>"
            }
            httpService.post("https://registration-api.eteyelo.com/registration/request-for-registration?api-version=1.0", subscription).then(
                    res => {
                        
                        const badge = null;
                        this.setState({badge});
                        swal("Rapport", "enregistrement reussi !", "success");
                        httpService.post("https://napoche-apis.eteyelo.com/Email/send-email", data);
                        setTimeout(function() {
                            this.setState({displayValide: 'none'});
                            this.props.history.push("/");
                        }.bind(this),6000);

                    }).catch(
                        err => {
                            swal("Erreur", err.response.data.message, "error");
                            this.setState({displayValide: 'none'});
                    })
                   
        }
        else {
            this.setState({displayValide: 'none'});
            const badge = "Veuillez completer correctement tous les champs";
            swal("Erreur", badge, "error");
        }
    }
    handlePostule = () => {
        this.setState({displayValide: 'inline-block'});
        const {positionValide, classes, enfants, subscription, tuteurEmail, phone} = this.state;
        const telephone = phone.replace('+', '');
        //console.log(positionValide);
        if (positionValide.length == 0) {
            swal("Erreur", "veuillez selectionner un enfant svp !", "error");
            this.setState({displayValide: 'none'});
            
            return
        }
        
        positionValide.map(elm => {
            const pos = parseInt(elm);
            enfants[pos]['codeEcole'] = subscription["codeEcole"];
            enfants[pos]['tuteurPhone'] = telephone;
            enfants[pos]['tuteurEmail'] = tuteurEmail;
            
            httpService.post("https://registration-api.eteyelo.com/reenrollement/request-reenrollement?api-version=1.0", enfants[pos]).then(
                res => {
                    const data = {
                        email: enfants[pos]['tuteurEmail'],
                        message:
                        "<!doctype html>"+
                        "<html>"+
                            "<head>"+
                                "<meta charset='utf-8'>"+
                                "<meta name='viewport' content='width=device-width, initial-scale=1'>"+
                                "<title>+KlasRoom</title>"+
                                "<link href='https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css' rel='stylesheet'>"+
                                "<link href='' rel='stylesheet'>"+
                                "<script type='text/javascript' src='https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js'>"+"</script>"+
                                "<script type='text/javascript' src='https://stackpath.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.bundle.min.js'>"+"</script>"+
                                "<script type='text/javascript'>"+"</script>"+
                            "</head>"+
                            "<body oncontextmenu='return false' class='snippet-body'>"+
                                "<!DOCTYPE html>"+
                                "<html>"+
                                    "<head>"+
                                        "<meta http-equiv=\"Content-Type\" content=\"text/html; charset=utf-8\" />"+
                                        "<meta name=\"viewport\" content=\"width=device-width, initial-scale=1\">"+
                                        "<meta http-equiv=\"X-UA-Compatible\" content=\"IE=edge\" />"+
                                        "<style type=\"text/css\">"+
                                            "@media screen {"+
                                                "@font-face {font-family: 'Lato';font-style: normal;font-weight: 400;"+
                                                    "src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v11/qIIYRU-oROkIk8vfvxw6QvesZW2xOQ-xsNqO47m55DA.woff) format('woff');}"+
                    
                                                "@font-face { font-family: 'Lato';font-style: normal;font-weight: 700;"+
                                                    "src: local('Lato Bold'), local('Lato-Bold'), url(https://fonts.gstatic.com/s/lato/v11/qdgUG4U09HnJwhYI-uK18wLUuEpTyoUstqEm5AMlJo4.woff) format('woff');}"+
                    
                                                "@font-face {font-family: 'Lato';font-style: italic;font-weight: 400;"+
                                                    "src: local('Lato Italic'), local('Lato-Italic'), url(https://fonts.gstatic.com/s/lato/v11/RYyZNoeFgb0l7W3Vu1aSWOvvDin1pK8aKteLpeZ5c0A.woff) format('woff');}"+
                    
                                                "@font-face {font-family: 'Lato';font-style: italic;font-weight: 700;"+
                                                    "src: local('Lato Bold Italic'), local('Lato-BoldItalic'), url(https://fonts.gstatic.com/s/lato/v11/HkF_qI1x_noxlxhrhMQYELO3LdcAZYWl9Si6vvxL-qU.woff) format('woff');}"+
                                            "}body,table,td,a {-webkit-text-size-adjust: 100%;-ms-text-size-adjust: 100%;}"+
                    
                                            "table,td {mso-table-lspace: 0pt;mso-table-rspace: 0pt;}"+
                    
                                            "img {-ms-interpolation-mode: bicubic;}"+
                    
                                            "img {border: 0;height: auto;line-height: 100%;outline: none;text-decoration: none;}"+
                    
                                            "table {border-collapse: collapse !important;}"+
                    
                                            "body { height: 100% !important;margin: 0 !important;padding: 0 !important;width: 100% !important;}"+
                    
                                            "a[x-apple-data-detectors] {color: inherit !important;text-decoration: none !important;font-size: inherit !important;font-family: inherit !important;font-weight: inherit !important;line-height: inherit !important;}"+
                    
                                            "@media screen and (max-width:600px) {h1 {font-size: 32px !important;line-height: 32px !important;}}"+
                    
                                            "div[style*=\"margin: 16px 0;\"] {margin: 0 !important;}"+
                                        "</style>"+
                                    "</head>"+
                    
                                    "<body style=\"background-color: #f4f4f4; margin: 0 !important; padding: 0 !important;\">"+
                                        "<div style=\"display: none; font-size: 1px; color: #fefefe; line-height: 1px; font-family: 'Lato', Helvetica, Arial, sans-serif; max-height: 0px; max-width: 0px; opacity: 0; overflow: hidden;\">"+"</div>"+
                                        "<table border=\"0\" cellpadding=\"0\" cellspacing=\"0\" width=\"100%\">"+
                                            "<tr>"+
                                                "<td bgcolor=\"#007bff\" align=\"center\">"+
                                                    "<table border=\"0\" cellpadding=\"0\" cellspacing=\"0\" width=\"100%\" style=\"max-width: 600px;\">"+
                                                        "<tr>"+
                                                            "<td align=\"center\" valign=\"top\" style=\"padding: 40px 10px 40px 10px;\"></td>"+
                                                        "</tr>"+
                                                    "</table>"+
                                                "</td>"+
                                            "</tr>"+
                                            "<tr>"+
                                                "<td bgcolor=\"#007bff\" align=\"center\" style=\"padding: 0px 10px 0px 10px;\">"+
                                                    "<table border=\"0\" cellpadding=\"0\" cellspacing=\"0\" width=\"100%\" style=\"max-width: 600px;\">"+
                                                        "<tr>"+
                                                            "<td bgcolor=\"#ffffff\" align=\"center\" valign=\"top\" style=\"padding: 40px 20px 20px 20px; border-radius: 4px 4px 0px 0px; color: #111111; font-family: 'Lato', Helvetica, Arial, sans-serif; font-size: 48px; font-weight: 400; letter-spacing: 4px; line-height: 48px;\">"+
                                                                "<img src=\"https://firebasestorage.googleapis.com/v0/b/klasroom-ae6da.appspot.com/o/logok.png?alt=media&token=9e41439f-4214-4bfd-8e8d-975bef930e83\" width=\"150\" height=\"120\" style=\"display: block; border: 0px;\" />"+
                                                                "<h1 style=\"font-size: 32px; font-weight: 380; margin: 2;\">inscription</h1>"+
                                                            "</td>"+
                                                        "</tr>"+
                                                    "</table>"+
                                                "</td>"+
                                            "</tr>"+
                                            "<tr>"+
                                                "<td bgcolor=\"#f4f4f4\" align=\"center\" style=\"padding: 0px 10px 0px 10px;\">"+
                                                    "<table border=\"0\" cellpadding=\"0\" cellspacing=\"0\" width=\"100%\" style=\"max-width: 600px;\">"+
                                                        "<tr>"+
                                                            "<td bgcolor=\"#ffffff\" align=\"left\" style=\"padding: 20px 30px 40px 30px; color: #666666; font-family: 'Lato', Helvetica, Arial, sans-serif; font-size: 18px; font-weight: 400; line-height: 25px;\">"+
                                                                "<p style=\"margin: 0; text-align: center;\">Nous avons bien reçu votre demande d’inscription, nous vous revenons dans 72 heure après le traitement du dossier.<br>Merci vérifier votre messagerie.</p>"+
                                                            "</td>"+
                                                        "</tr>"+
                                                    "</table>"+
                                                "</td>"+
                                            "</tr>"+
                                            
                                        "</table>"+
                                    "</body>"+
                    
                                "</html>"+
                            "</body>"+
                    "</html>"
                    }
                    swal("Rapport", "confirmation reussie !", "success");
                    if (tuteurEmail != "") {
                        httpService.post("https://napoche-apis.eteyelo.com/Email/send-email", data);
                    }
                    setTimeout(function() {
                        this.setState({displayValide: 'none'});
                        this.props.history.push("/");
                    }.bind(this),6000);
                }
            ).catch(
                err => {
                    swal("Erreur", err.response.data.message, "error");
                    this.setState({displayValide: 'none'});
                }
            )
        }) 
    }
    render() { 
        const {disable, edisable, ecole, logo, enfants, checked, unchecked, displayEcole, displayTelephone, displayValide} = this.state;
        let count = 0;
        
        const executeScroll2 = () => {
            this.props.history.push("/");
        }
        const executeScroll = () => {
            this.props.history.push("/");
        }
        const executeScroll1 = () => {
            this.props.history.push("/");
        }
        return ( 
            <div>
                <Navbar executeScroll={executeScroll} executeScroll1={executeScroll1} executeScroll2={executeScroll2}/>
                <div className="modal" tabIndex="-1" role="dialog" id="GSCCModal">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content" id="modal">
                            <div className="modal-header">
                                <h6 className="modal-title">demande reussie !</h6>
        
                            </div>
                        </div>
                    </div>
                </div>
                <section id="hero">
                    <svg className="hero-waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 120 28 " preserveAspectRatio="none" >
                    <defs>
                        <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g className="wave1">
                        <use xlinkHref="#wave-path" x="50" y="3" fill="rgba(255,255,255, .1)" />
                    </g>
                    <g className="wave2">
                        <use xlinkHref="#wave-path" x="50" y="0" fill="rgba(255,255,255, .2)" />
                    </g>
                    <g className="wave3">
                        <use xlinkHref="#wave-path" x="50" y="9" fill="#fff" />
                    </g>
                    </svg>
                </section>
                <section id="contact" className="contact">
                    
                    <div className="container">

                        <div className="section-title" data-aos="fade-up">
                            <h2>Demande d'inscription</h2>
                        </div>

                        <div className="row">

                        <div className="col-lg-2" data-aos="fade-right" data-aos-delay="100" style={{paddingTop: '20%'}}>
                            <h6>{ecole}</h6>
                            <img src={logo} alt="" width="150px" />
                        </div>

                        <div className="col-lg-10 mt-3 mt-lg-0" data-aos="fade-left" data-aos-delay="200">
                            <div className="section-title" data-aos="fade-up">
                                <h3 style={{textAlign: 'center'}}>Veuillez remplir le formulaire</h3>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inline" value="1" onChange={this.handelInscript} checked={checked}/>
                                    <label className="form-check-label" htmlFor="inlineRadio1">Nouvel élève</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="inline" value="2" onChange={this.handelInscript} checked={unchecked}/>
                                    <label className="form-check-label" htmlFor="inlineRadio2">Ancien élève</label>
                                </div>
                            </div>
                            <p style={{textAlign: 'center'}}><span className="badge badge-danger">{this.state.badge}</span></p>

                            <div className="php-email-form">
                                <div className="form-row">
                                    <div className="col-md-8 form-group">
                                        <label className="form-check-label">Code de l'école</label> <span style={{color: 'red'}}>*</span>
                                        <input type="text" className="form-control" name="codeEcole"   placeholder="Veuillez saisir le code de l'école" onChange={this.handelChange} required disabled={edisable} />
                                        <div className="validate"></div>
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <label id="ecole" className="form-check-label">Verification de l'école</label> <span style={{color: 'red'}}>*</span><br />
                                        <button className="btn btn-primary" onClick={this.handelEcole} disabled={edisable}>valider</button>
                                        <div className="spinner-border text-primary mx-2 px-1" style={{display: displayEcole}}></div>
                                    </div>
                                </div>
                                <div id="inscription">
                                    <div className="form-row">
                                        <div className="col-md-12 form-group">
                                            <label className="form-check-label">Classe solicitée : </label><span style={{color: 'red'}}>*</span>
                                            
                                                <select name="classe" disabled={disable} className="form-control" onChange={this.handelChange}>
                                                {this.state.classes.map(elm =>
                                                <option key={elm.codeClasse} value={elm.codeClasse}>{elm.intituleClasse}</option>
                                                )}
                                            </select>
                                        </div>
                                        <br />
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-6 form-group">
                                            <label>Nom</label><span style={{color: 'red'}}>*</span>
                                            <input type="text" className="form-control" name="nomEleve" disabled={disable} placeholder="Nom" onChange={this.handelChange} />
                                            <div className="validate"></div>
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label>Post-nom</label><span style={{color: 'red'}}>*</span>
                                            <input type="text" className="form-control" name="postnomEleve" disabled={disable} placeholder="Post-nom" onChange={this.handelChange} required/>
                                            <div className="validate"></div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-6 form-group">
                                            <label>Prenom</label><span style={{color: 'red'}}>*</span>
                                            <input type="text" className="form-control" name="prenomEleve" disabled={disable} placeholder="Prenom" onChange={this.handelChange} required/>
                                            <div className="validate"></div>
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label>Lieu de naissance</label><span style={{color: 'red'}}>*</span>
                                            <input type="text" className="form-control" name="lieuNaissance" disabled={disable} placeholder="Lieu de naissance" onChange={this.handelChange} required/>
                                            <div className="validate"></div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-6 form-group">
                                            <label>Sexe de l'élève</label> <span style={{color: 'red'}}>*</span>
                                                <select className="form-control" disabled={disable} name="sexeEleve" onChange={this.handelChange}>
                                                    <option key={0}>Homme</option>
                                                    <option key={1}>Femme</option>
                                                </select>
                                            <div className="validate"></div>
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label>Date de naissance</label> <span style={{color: 'red'}}>*</span>
                                            <input type="date" className="form-control" name="dateNaissanceEleve" disabled={disable} onChange={this.handelChange} required/>
                                            <div className="validate"></div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-6 form-group">
                                            <label>Nom du tuteur</label> <span style={{color: 'red'}}>*</span>
                                            <input type="text" className="form-control" name="nomTuteur" disabled={disable} placeholder="Nom du tuteur" onChange={this.handelChange} required/>
                                            <div className="validate"></div>
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label>Postnom du tuteur</label> <span style={{color: 'red'}}>*</span>
                                            <input type="text" className="form-control" name="postnomTuteur" disabled={disable} placeholder="Postnom du tuteur" onChange={this.handelChange} required/>
                                            <div className="validate"></div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-6 form-group">
                                            <label>Prenom du tuteur</label> <span style={{color: 'red'}}>*</span>
                                            <input type="text" className="form-control" name="prenomTuteur" disabled={disable} placeholder="Prenom du tuteur" onChange={this.handelChange} required/>
                                            <div className="validate"></div>
                                            
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label>Sexe du tuteur</label> <span style={{color: 'red'}}>*</span>
                                                <select className="form-control" disabled={disable} name="sexeTuteur" onChange={this.handelChange}>
                                                    <option key={0}>Homme</option>
                                                    <option key={1}>Femme</option>
                                                </select>
                                            <div className="validate"></div>
                                        </div>
                                        
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-1 form-group">
                                            <label>Code</label> <span style={{color: 'red'}}>*</span>
                                            <input type="number" className="form-control" defaultValue="243" readOnly />
                                            </div>
                                        <div className="col-md-5 form-group">
                                            <label>Téléphone du tuteur</label> <span style={{color: 'red'}}>*</span>
                                            <input type="number" className="form-control" max="9" maxLength="9" name="telephoneTuteur" disabled={disable} placeholder="Telephone du tuteur" onChange={this.handelChange} required/>
                                            <div className="validate"></div>
                                        </div>
                                        <div className="col-md-6 form-group">
                                            <label>Email du tuteur</label>
                                            <input type="email" className="form-control" name="emailTuteur" disabled={disable} placeholder="Email du tuteur" onChange={this.handelChange} required/>
                                            <div className="validate"></div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                    <div className="col-md-12 form-group">
                                    <label>Adresse</label> <span style={{color: 'red'}}>*</span>
                                        <input type="text" className="form-control" name="adresse" disabled={disable} placeholder="Adresse" onChange={this.handelChange} required/>
                                        <div className="validate"></div>
                                    </div>
                                </div>
                                
                                    <div className="text-center"><button type="submit" onClick={this.handleSubmit}>Postuler</button><div className="spinner-border text-primary mx-2 px-1 px-1 " style={{display: displayValide}}></div></div>
                                </div>

                                <div id="reinscription" style={{display: 'none'}}>
                                    <div className="form-row">
                                        <div className="col-md-8 form-group">
                                            <label>Numéro enregistré à l'école</label><span style={{color: 'red'}}>*</span>
                                            <input type="text" className="form-control" defaultValue="+243" min={13} max={13} name="phone" disabled={disable} placeholder="xxx" onChange={this.handelChange} required/>
                                            <div className="validate"></div>
                                        </div>
                                        <div className="col-md-4 form-group">
                                            <label id="ecole">Verification du numéro</label> <span style={{color: 'red'}}>*</span><br />
                                            <button className="btn btn-primary" onClick={this.handelPhone}>valider</button>
                                            <div className="spinner-border text-primary mx-2 px-1 px-1" style={{display: displayTelephone}}></div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-12 form-group">
                                            <label className="form-check-label">Email du Tuteur</label>
                                            <input type="email" className="form-control" name="tuteurEmail" disabled={disable} placeholder="Veuillez saisir l'Email du tuteur" onChange={this.handelChange} required/>
                                            <div className="validate"></div>
                                        </div>
                                    </div>
                                    <table className="table table-hover" style={{overflow: '-moz-hidden-unscrollable'}}>
                                        <thead>
                                            <tr key={'0164'}>
                                                <th scope="col">Nom</th>
                                                <th scope="col">Postnom</th>
                                                <th scope="col">Prénom</th>
                                                <th scope="col">Sexe</th>
                                                <th scope="col">Classe de provenance</th>
                                                <th scope="col">Selectionner</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {enfants.map(item =>
                                                <tr key={item.code}>
                                                    <td>{item.nom}</td>
                                                    <td>{item.postnom}</td>
                                                    <td>{item.prenom}</td>
                                                    <td>{item.sexe}</td>
                                                    <td>{item.intituleClasse}</td>
                                                    <td>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" value={count++} onChange={this.handelSelect} type="checkbox" id="inlineCheckbox1" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    <div className="text-center">
                                        <button type="submit" onClick={this.handlePostule}>Confirmer</button>
                                    </div>
                                    <div className="spinner-border text-primary mx-2 px-1 px-1" style={{display: displayValide}}></div>
                                </div>
                            </div>
                            
                        </div>

                        </div>

                    </div>
                </section>

                <div className="modal" tabIndex="-1" role="dialog" style={{display: this.state.show}}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <p>{this.state.message}</p>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
        );
    }
}
 
export default Inscription;