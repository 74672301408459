import React, { Component } from 'react';
class Contact extends Component {
    render() { 
        const {myRef1} = this.props;
        return ( 
            <React.Fragment>
                <section ref={myRef1} id="contact" className="contact">
                    <div className="container">

                        <div className="section-title" data-aos="fade-up">
                        <h2>Contact</h2>
                        </div>

                        <div className="row">

                        <div className="col-lg-4" data-aos-delay="100">
                            <img src="assets/img/logok.png" width="90%"/>
                            <div className="info t">
                                <div className="address">
                                    <i className="icofont-twitter"></i>
                                </div>
                                <div className="address">
                                    <i className="icofont-facebook"></i>
                                </div>
                                <div className="address">
                                    <i className="icofont-linkedin"></i>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-4" data-aos-delay="100">
                            <div className="info">
                                <div className="email">
                                    <i className="icofont-envelope"></i>
                                    <p> info@klasroom.education</p>
                                </div>
                                <div className="email">
                                    <i className="icofont-phone"></i>
                                    <p>+243 812 155 559</p>
                                </div>
                                <div className="email">
                                    <i className="icofont-phone"></i>
                                    <p>+243 828 807 710</p>
                                </div>
                                
                            </div>

                        </div>
                        <div className="col-lg-4" data-aos-delay="100">
                            <div className="info">
                                <div className="email">
                                    <i className="icofont-google-map"></i>
                                    <p>57, Av. Mwepu, Lubumbashi - RDC.</p>
                                </div>
                            </div>

                        </div>

                        </div>

                    </div>
                </section>
            </React.Fragment>
         );
    }
}
 
export default Contact;