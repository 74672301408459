import axios from 'axios';
import * as Sentry from "@sentry/react";

axios.interceptors.response.use(null, error => {
    const exceptedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;
      if (!exceptedError) {
        Sentry.captureException(error);
      }
      return Promise.reject(error);
});

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete
}